@import "./_exports.module.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white: white;
  --gray-50: #ecf0ff;
  --gray-55: #ecf0ff;
  --gray-100: #d3d4dd;
  --gray-200: #aaaab9;
  --gray-800: #3a3a47;
  --teal-200: #84aaff;
  --teal-300: #498fff;
  --teal-600: #498fff;
  --primary-color: #498fff;
  --text-color: #000000;

  --ds-color-primary: #007af0;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

body {
  background: var(--gray-800);
  color: var(--white);
  -webkit-font-smoothing: antialiased;
}

body,
input,
textarea,
select,
button {
  font: 400 1rem "IBM Plex Sans", sans-serif;
}

button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}
