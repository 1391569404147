.bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;

    .porcentagem {
        margin-top: 0.5rem;
        font-size: 2.5rem;
        font-weight: 700;
        color: #343131;
    }

    .nomeGrafico {
        font-size: 1.125rem;
        font-weight: 500;
        text-align: center;
    }

    .vertical {
        font-size: 1.25rem;
        font-weight: 500;

        width: 30px;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
    }

    .react-financial-charts {
        position: absolute !important;
        top: 0;
        left: 0;
    }

    canvas {
        position: absolute !important;
        top: 0;
        left: 0;
    }
}
