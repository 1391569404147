@import "./_exports.module.scss";
#page-painel {
  width: 100%;
  height: 100vh;

  @media (max-width: 591px) {
    .contentWrapper {
      padding: 32px 4px;
    }
  }

  .contentWrapper {
    min-height: 711px;

    .aviso {
      color: red;
    }

    .iconCircle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      color: #ffffff;
      font-size: 0.8rem;
    }

    .iconRural {
      background-color: #78b4d0;
    }

    .iconUrbano {
      background-color: #2775b0;
    }

    .nomeGestante {
      font-size: 0.9rem;
      color: #707070;
      text-decoration: underline;
      cursor: pointer;
    }

    .pagination-bar {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .containerButtons {
      .btn-light {
        width: 300px;
        height: 38px;
        background-color: #ffffff;
        border-radius: 5px;
        margin: 0;
        border: 1px solid #343131;

        font-size: 1.125rem;
        font-weight: 400;
        color: #343131;
      }

      .btn-primary {
        width: 170px;
        height: 38px;
        background-color: #343131;
        border-radius: 5px;
        margin: 0;
        border: none;

        font-size: 1.125rem;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
