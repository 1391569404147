@import "./_exports.module.scss";
footer {
  display: flex;
  height: 16vh;
  flex-direction: column;

  background: white;
  box-shadow: inset 0px 6px 8px -10px #000000, inset 0 0 0 0 #fff;

  align-items: center;
  justify-content: center;

  .grid-sponsors {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    img {
      max-width: 250px;
      margin: 8px 16px;
    }
  }
  .version {
    color: #000000;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: small;
    position: relative;
    top: -29px;
    margin-bottom: 30px;

    div {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: 591px) {
  footer {
    height: 40%;
  }
}
