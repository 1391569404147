@import "./_exports.module.scss";
#page-selecionar-ubs {
  width: 100%;
  height: 100vh;

  .contentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 25.2vh);

    background: white;
    flex: 1;
    padding: 32px;

    color: var(--gray-800);

    .container-titulo {
      h2 {
        font-size: 1.5rem;
        font-weight: 700;
        color: #343131;
      }
    }

    .container-escolher-ubs {
      .container-municipio {
        display: flex;

        .btn-primary {
          width: 276px;
          height: 44px;

          background-color: #343131;
          border-radius: 10px;
          border-color: #343131;

          font-size: 1.3rem;
          font-weight: 500;
          color: white;
          margin: 0;
        }
      }

      .container-combo-ubs {
        .combo-ubs {
          width: 276px;
          height: 44px;
          background-color: var(--primary-color);
          color: #343131;
          font-size: 1.3rem;
          font-weight: 500;
          text-align: left;
          border: none;
          border-radius: 0.25rem;
          cursor: pointer;

          scrollbar-width: auto;
          scrollbar-color: var(--primary-color) white;

          option {
            background-color: #fff;
            font-size: 1rem;
            font-weight: 400;
            color: #343131;
            text-align: left;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
          }
        }

        /* Chrome, Edge, and Safari */
        .combo-ubs ::-webkit-scrollbar {
          width: 16px;
        }

        .combo-ubs ::-webkit-scrollbar-track {
          background: white;
        }

        .combo-ubs ::-webkit-scrollbar-thumb {
          background-color: var(--primary-color);
          border-radius: 10px;
          border: 3px solid white;
        }
      }
    }
  }
}
