.pie {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 138px;

    .porcentagem {
        margin-top: 0.5rem;
        font-size: 2.5rem;
        font-weight: 700;
        color: #343131;
    }

    .nomeGrafico {
        font-size: 1.125rem;
        font-weight: 500;
        text-align: center;
    }
}
.pie-chart{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 138px;

    .porcentagem {
        margin-top: 0.5rem;
        font-size: 2rem;
        font-weight: 700;
        color: #343131;
    }

    .nomeGrafico {
        font-size: 1.125rem;
        font-weight: 500;
        text-align: center;
    }
}
