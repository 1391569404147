@import "./_exports.module.scss";
#page-painel {
  width: 100%;
  height: 100vh;

  .contentWrapperLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 25.2vh);
    background: white;
    color: var(--gray-800);
  }

  .contentWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    flex: 1;
    padding: 32px;

    color: var(--gray-800);

    .linha {
      width: 49px;
      height: 5px;
      background-color: #515162;
      opacity: 1;
    }

    .container-atendimentos {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #515162;
      border-radius: 20px;
      padding: 8px 32px;
      cursor: pointer;

      .titulo {
        font-size: 1.125rem;
        font-weight: 700;
        color: white;
      }

      .info {
        cursor: pointer;
      }

      .separador {
        width: 36px;
        height: 2px;
        background-color: white;
        opacity: 1;
      }

      .total-trimestre {
        font-size: 2.5rem;
        font-weight: 700;
        color: white;
      }
    }

    .container-semana {
      margin: 0 16px;
      padding: 8px 16px;
      border: 1px solid #515162;
      border-radius: 5px;
      cursor: pointer;

      font-size: 1.125rem;
      font-weight: 700;
    }

    h4 {
      font-size: 1.5rem;
      color: #515162;
    }

    .situacao-exames {
      .bordas {
        border-radius: 5px;
        border: 1px solid #515162;
      }
      .tipo {
        font-size: 1.125rem;
        font-weight: 700;
        color: #515162;
      }
    }

    .painel-secundario {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .container-extratificacao-atendimentos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 28px;
        border-bottom: 1px solid #7a7b8e;
      }

      .progress {
        border-radius: 0 !important;
      }

      .bg-color-1 {
        background-color: var(--teal-300);
      }
      .bg-color-2 {
        background-color: #42005b;
      }
      .bg-color-3 {
        background-color: var(--teal-300);
      }

      .profissao-nome {
        width: 350px;
        font-size: 1.2rem;
      }
      .total {
        width: 60px;
      }
    }

    .btn-secondary {
      width: 349px;
      height: 38px;
      background-color: #343131;
      border-radius: 5px;

      font-size: 1.125rem;
      font-weight: 700;
      color: white;
      margin: 0;
    }

    .btn-light {
      width: 278px;

      background-color: white;
      border-radius: 5px;
      border: 1px solid #515162;

      font-size: 1.125rem;
      font-weight: 700;
      color: #515162;
    }

    .btn-primary {
      height: 38px;
      background-color: var(--teal-300);
      border-radius: 5px;
      margin: 0;
      padding: 0 16px;
      border: none;

      font-size: 1.125rem;
      font-weight: 700;
      color: #515162;
    }
  }
}
