#modal .container-modal-list {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.container-modal-list .modal {
  z-index: 1;
  width: 100%;
  max-width: 598px;
  height: auto;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  animation: animate 0.3s;
  font-size: 1.125rem;
  font-weight: 500;
  color: #343131;
}

.modal .separador {
  width: 49px;
  height: 5px;
  background-color: #343131;
  opacity: 1;
}

.modal h1 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #343131;
}

.modal .closeButton {
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
}

.modal ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.modal .grid-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.375rem;

  list-style-type: none;
  margin: 0 auto;
  padding: 0;
}

.modal .grid-list li {
  list-style: none;
  font-size: 0.9rem;
  color: #707070;
  text-decoration: underline;
  cursor: pointer;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

@keyframes animate {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

/* ANIMACAO DOTS */

.barsLoading {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 2em;
  margin-left: 1rem;
}

.barsLoading span {
  width: 0.3em;
  height: 0.7em;
  background-color: #ccc;
}

.barsLoading span:nth-of-type(1) {
  animation: grow 1s -0.45s ease-in-out infinite;
}

.barsLoading span:nth-of-type(2) {
  animation: grow 1s -0.3s ease-in-out infinite;
}

.barsLoading span:nth-of-type(3) {
  animation: grow 1s -0.15s ease-in-out infinite;
}

.barsLoading span:nth-of-type(4) {
  animation: grow 1s ease-in-out infinite;
}

@keyframes grow {
  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }
}
