@import "./_exports.module.scss";

header {
  display: flex;
  align-items: center;
  background-color: var(--ds-color-primary);

  .siteInfo {
    display: flex;
    flex: 1;
    align-items: center;
    background-color: #343131;
    padding: 8px 16px;
    color: #84aaff;

    .logoName {
      display: flex;
      cursor: pointer;
      align-items: center;
    }

    img {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }

    strong {
      font-size: 18px;

      span {
        color: white;
      }
    }
  }

  .userInfo {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex: 1;
    padding: 0 32px;
    color: white;

    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    span {
      font-size: 1rem;
    }

    .logoutWrapper {
      display: flex;
      align-items: center;
      margin-left: 40px;
      cursor: pointer;

      a {
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }
}
